<template>
  <Layout navbarAbsolute navbarTransparent>
    <div class="pt-5 ql-editor" style="background: #e8f1f8; padding-bottom: 9em">
      <div class="my-5 container" id="PSCM-1" style="">
        <h1 style="max-width: 550px" class="font-weight-bold text-primary-darker">
          We Are Proud To Be Your Closest Partner Success
        </h1>
        <p style="max-width: 700px" class="mt-4">
          Your success is our dream. In the midst of changes that occur so quickly, we
          strive to be your closest partner who is always present to grow and develop
          together to achieve success.
        </p>
      </div>
    </div>

    <div class="p-5">
      <div class="container">
        <div class="position-">
          <div
            class="d-flex row justify-content-center"
            style="z-index: 0; margin-top: -12em"
          >
            <div
              class="shadow col-md-8 p-0 m-0"
              style="border-radius: 30px; background: #fff"
            >
              <div
                class="row align-items-center justify-content-center p-0 m-0"
                style="width: fit-content"
              >
                <div class="col-md-auto d-flex px-0">
                  <img
                    src="@/static/Frame 1120 (1).png"
                    class="w-100"
                    style="border-radius: 30px; max-width: 400px;height:250px"
                  />
                </div>
                <div class="col-md">
                    <div class="ql-editor" style="height:250px;overflow-y:auto">
                        <div class="mt-3" id="PSCM-2">
                            Your success is our dream. In the midst of changes that occur so
                            quickly, we strive to be your closest partner who is always present to
                            grow and develop together to achieve success.
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-center justify-content-center" style="margin: 7em 0">
          <div class="col-lg-6 my-2">
            <div class="ql-editor">
              <div id="PSCM-3">
                <h1 style="max-width: 550px" class="font-weight-bold text-primary-darker">
                  We Are Proud To Be Your Closest Partner Success
                </h1>
                <p style="max-width: 700px" class="mt-4">
                  Your success is our dream. In the midst of changes that occur so
                  quickly, we strive to be your closest partner who is always present to
                  grow and develop together to achieve success.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-auto d-flex justify-content-center my-1">
            <img
              src="@/static/Group 739.png"
              class="w-100"
              style="border-radius: 30px; max-width: 400px"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5" style="background: #e8f1f8">
      <div class="container w-100 pb-0 mb-0" style="">
        <div class="row">
          <div class="col-lg-4 my-auto d-flex" style="">
            <div
              style="background: rgba(82, 155, 213, 0.11);
backdrop-filter: blur(35.6693px);border-radius: 20px; max-width: 350px"
              class="p-1 ml-auto w-100 mt-4 ql-editor"
            >
              <p style="max-width: 700px" class="" id="PSCM-4">
                Your success is our dream. In the midst of changes that occur so quickly,
                we strive to be your closest partner who is always present to grow and
                develop together to achieve success.
              </p>
            </div>
          </div>
          <div class="col-lg-4 d-flex">
            <img
              src="@/static/Section-3.png"
              class="mx-auto w-100"
              style="
                bottom: 0;
                margin-top: -10em;
                object-fit: contain;
                object-position: bottom;
              "
            />
          </div>
          <div class="col-lg-4 my-auto d-flex" style="">
            <div
              style="background: rgba(82, 155, 213, 0.11);
backdrop-filter: blur(35.6693px);border-radius: 20px; max-width: 350px"
              class="p-1 mr-auto w-100 mb-4 ql-editor"
            >
              <p style="max-width: 700px" class="" id="PSCM-5">
                Your success is our dream. In the midst of changes that occur so quickly,
                we strive to be your closest partner who is always present to grow and
                develop together to achieve success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="padding: 10em 0">
      <div class="ql-editor">
        <div>
          <h1 style="" class="font-weight-bold text-primary-darker text-center" id="PSCM-6">
            We Are Proud To Be Your Closest Partner Success
          </h1>
        </div>

        <div class="d-flex flex-wrap justify-content-center mt-5" style="gap:30px;padding:0 20%">
            <!-- <template v-for="item in ['Persuasive', 'Staffing', 'Sequencing', 'Recruiting Support', 'Energizing New Behavior']"> -->
                <div class="p-2 d-flex flex-column text-center align-items-center " style="border: 2px solid #0B5087;border-radius:20px;width:200px">
                    <img src="@/static/Persuasive.png" class="mb-2" style="width:70px" />
                    <h3 style="color:#0B5087;font-weight:bolder" class="mb-0 h6" id="PSCM-7">Persuasive</h3>
                </div>
                <div class="p-2 d-flex flex-column text-center align-items-center " style="border: 2px solid #0B5087;border-radius:20px;width:200px">
                    <img src="@/static/Staffing.png" class="mb-2" style="width:70px" />
                    <h3 style="color:#0B5087;font-weight:bolder" class="mb-0 h6" id="PSCM-8">Staffing</h3>
                </div>
                <div class="p-2 d-flex flex-column text-center align-items-center " style="border: 2px solid #0B5087;border-radius:20px;width:200px">
                    <img src="@/static/Sequencing.png" class="mb-2" style="width:70px" />
                    <h3 style="color:#0B5087;font-weight:bolder" class="mb-0 h6" id="PSCM-9">Sequencing</h3>
                </div>
                <div class="p-2 d-flex flex-column text-center align-items-center " style="border: 2px solid #0B5087;border-radius:20px;width:200px">
                    <img src="@/static/Recruiting Support.png" class="mb-2" style="width:70px" />
                    <h3 style="color:#0B5087;font-weight:bolder" class="mb-0 h6" id="PSCM-10">Recruiting Support</h3>
                </div>
                <div class="p-2 d-flex flex-column text-center align-items-center" style="border: 2px solid #0B5087;border-radius:20px;width:200px">
                    <img src="@/static/Energizing New Behavior.png" class="mb-2" style="width:70px" />
                    <h3 style="color:#0B5087;font-weight:bolder" class="mb-0 h6" id="PSCM-11">Energizing New Behavior</h3>
                </div>
            <!-- </template> -->
        </div>

      </div>
    </div>

    <div class="px-4 pt-4" style="background: #e8f1f8;margin-bottom:13em">
      <div class="container w-100 d-flex justify-content-center" style="">
        <!-- <div style="max-width:500px"> -->
            <img src="@/static/Section-5.png" style="max-width:400px" class="ml-auto" />
        <!-- </div> -->
            <div
              style="background: rgba(82, 155, 213, 0.11);
backdrop-filter: blur(35.6693px);border-radius: 20px; max-width: 350px;margin-left:-4em"
              class="p-1 mr-auto my-auto w-100 ql-editor"
            >
              <p style="max-width: 700px" class="p-1" id="PSCM-12">
                Your success is our dream. In the midst of changes that occur so quickly,
                we strive to be your closest partner who is always present to grow and
                develop together to achieve success.
              </p>
              <router-link to="/contacts" class="btn float-right d-flex align-items-center" style="background:#EF6D22;border-radius:20px;color:white !important">
                Contact Us <i class=" bx bxs-arrow-to-right" style="font-size:18px"></i>
              </router-link>
            </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import store from '@/store'

export default {
  components: {
    Layout,
  },
  methods: {
    async getNextDatas(){
      let temp = await store.dispatch(`page_content/getDataList`, {
          lang_code: this.currentLocale,
          code: 'PSCM',  
      }) 
      temp?.map((item, key) => {
        document.getElementById(item.code).innerHTML = item.content
      })
      this.$forceUpdate()
    },
  },
  mounted(){
      this.getNextDatas()
  },
  computed: {
    currentLocale() {
      return this.$store.state.locales.CURRENT
    },
  },
  watch: {
    currentLocale(val) {
      this.getNextDatas();
    },
  },
};
</script>
